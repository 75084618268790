import { render, staticRenderFns } from "./SearchPanelDesktop.vue?vue&type=template&id=59b9285c&scoped=true&"
import script from "./SearchPanelDesktop.vue?vue&type=script&lang=js&"
export * from "./SearchPanelDesktop.vue?vue&type=script&lang=js&"
import style0 from "./SearchPanelDesktop.vue?vue&type=style&index=0&id=59b9285c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59b9285c",
  null
  
)

export default component.exports