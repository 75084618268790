<template>
  <div class="categories">
    <div
      class="categories__handler"
    >
      <div class="categories_head" @click="hidden = !hidden">
        <h4 class="categories__heading">
          Filtruj według kategorii
        </h4>
        <i
          :class="!hidden ? 'top' : 'bottom'"
          class="arrow"
        />
      </div>
      <div v-show="!hidden" class="categories_list">
        <template v-for="category in categories">
          <button
            :key="category.category_id"
            class="categories__button no-outline bg-cl-transparent py10 px20"
            :class="{ 'categories__button--active': value.includes(category.category_id) }"
            type="button"
            @click="toggleCategory(category)"
          >
            {{ category.name }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      hidden: true
    }
  },
  methods: {
    toggleCategory (category) {
      const isSelected = this.value.includes(category.category_id)
      if (isSelected) {
        this.$emit('input', this.value.filter(categoryId => categoryId !== category.category_id))
      } else {
        this.$emit('input', [...this.value, category.category_id])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
$break-large: 1030px;

.categories {
  &__heading {
    width: 100%;
  }

  &__handler {
    display: flex;
    flex-wrap: wrap;
  }

  &__button {
    display: flex;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 2px solid #0B071A;
    transition: all 0.2s ease;

    &--active {
      background: #333;
      color: #fff;
    }

    &--active {
      text-decoration: underline;
    }
    @media screen and (min-width: $break-large) {
      &:hover{
        background: #0B071A;
        color: #fff;
      }
    }
  }

  &__button-text {
    display: block;
    margin-right: 15px;
  }

}
.categories_list {
  display: flex;
  flex-wrap: wrap;
}
.categories_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.arrow {
  border: solid #707070;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 4px;
  right: 20px;
  bottom: 22px;
}
.bottom {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.top {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
</style>
