<template>
  <div
    class="w-100 searchpanel"
    data-testid="searchPanel"
  >
    <label for="search" class="visually-hidden">
      {{ $t('Search') }}
    </label>
    <!-- {{ isToggleSearch }} -->
    <div class="search-input-group middle-xs" @click="toogleSearchModal = true">
      <i class="icon-search cl-white search-icon" />
      <input
        ref="search"
        id="search"
        v-model="search"
        @input="makeSearch"
        @blur="$v.search.$touch()"
        @keyup.enter="toggleQueryFilter"
        class="search-panel-input"
        :placeholder="$t('Czego szukasz w sklepie nr 1 w Polsce ?')"
        type="text"
      >
      <i v-if="search" @click="search = ''">
        <close-svg class="pointer clear-input" width="22px" height="100%" color="#ffffff" />
      </i>
    </div>
    <div class="overlay fixed w-100" @click="toogleSearchModal = false" v-if="isToggleSearch" />
    <transition name="fade">
      <div class="container search-layer bg-cl-primary" :class="{ 'active' : isToggleSearch }" v-show="isToggleSearch">
        <div class="product-listing row mt20">
          <product-tile
            v-for="(product, index) in visibleProducts"
            :key="product.id"
            :product="product"
            :position="index"
            list="search"
            @click.native="toogleSearchModal = false"
          />
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="container search-layer layer-2 bg-cl-secondary" :class="{ 'active' : isToggleSearch }" v-show="isToggleSearch">
        <div class="button-container mt20">
          <button-full
            @click.native="toggleQueryFilter"
            class="button__show-all pointer weight-700 col-xs-12 center-xs px15 fs16"
          >
            {{ $t('Pokaż wszystkie wyniki') }}
          </button-full>
        </div>
        <div class="categories">
          <category-panel :categories="categories" v-model="selectedCategoryIds" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SearchPanel from '@vue-storefront/core/compatibility/components/blocks/SearchPanel/SearchPanel'
import ProductTile from 'theme/components/core/ProductTile'
import VueOfflineMixin from 'vue-offline/mixin'
import CategoryPanel from 'theme/components/core/blocks/Category/CategoryPanel'
import { minLength } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'
import CloseSvg from 'theme/components/theme/blocks/Icons/CloseSvg.vue'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'

export default {
  components: {
    ProductTile,
    CategoryPanel,
    CloseSvg,
    ButtonFull
  },
  mixins: [SearchPanel, VueOfflineMixin],
  validations: {
    search: {
      minLength: minLength(3)
    }
  },
  data () {
    return {
      search: '',
      selectedCategoryIds: [],
      toogleSearchModal: true
    }
  },
  methods: {
    toggleQueryFilter () {
      this.toogleSearchModal = false
      this.$router.push({ path: '/kategorie-produktow', query: { query: this.search } })
      this.search = ''
    }
  },
  computed: {
    isQueryFilterActive () {
      return this.$store.state.ui.isQueryFilterActive
    },
    isToggleSearch (i) {
      var toggleSearch = false
      if (((this.visibleProducts.length && this.categories.length > 1) && this.search.length > 1) && this.toogleSearchModal) {
        toggleSearch = true
      } else {
        toggleSearch = false
      }
      return toggleSearch
    },
    visibleProducts () {
      const productList = this.products || []
      if (this.selectedCategoryIds.length) {
        return productList.filter(product => product.category_ids.some(categoryId => {
          const catId = parseInt(categoryId)
          return this.selectedCategoryIds.includes(catId)
        }))
      }
      return productList
    },
    categories () {
      const categoriesMap = {}
      this.products.forEach(product => {
        [...product.category].forEach(category => {
          categoriesMap[category.category_id] = category
        })
      })
      return Object.keys(categoriesMap).map(categoryId => categoriesMap[categoryId])
    },
    getNoResultsMessage () {
      let msg = ''
      if (!this.$v.search.minLength) {
        msg = 'Searched term should consist of at least 3 characters.'
      } else if (this.emptyResults) {
        msg = 'No results were found.'
      }
      return msg
    }
  },
  watch: {
    categories () {
      this.selectedCategoryIds = []
    }
  },
  mounted () {
    // add autofocus to search input field
    this.$refs.search.focus()
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/animations/transitions";
@import "~theme/css/variables/grid";
@import "~theme/css/variables/typography";

.searchpanel {
  // position: relative;
  .search-layer {
    box-shadow: -4px 4px 8px -4px #808080;
    &.layer-2 {
      box-shadow: 0px 8px 8px -7px #9a9a9a;
      right: 0px;
      max-width: 300px;
    }
    // &.active {
    //   opacity: 1;
    // }
    // opacity: 0;
    min-height: calc( 100vh - 56px);
    max-height: calc( 100vh - 56px);
    // min-height: 550px;
    // max-height: 550px;
    top: 56px;
    position: absolute;
    overflow-y: scroll;
    bottom: auto;
    overflow-y: auto;
    z-index: 3;
    // right: 0;
    // max-width: 1040px;
    right: 300px;
    max-width: 740px;
    width: 100%;
    padding: 0;
  }
  .close-icon-row {
    display: flex;
    justify-content: flex-end;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;

    @media #{$media-xs} {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .row {
    margin-left: - map-get($grid-gutter-widths, lg) / 2;
    margin-right: - map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      margin-right: - map-get($grid-gutter-widths, xs) / 2;
      margin-left: - map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .col-md-12 {
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .product-listing {
    // padding-top: 30px;
  }

  .product {
    box-sizing: border-box;
    width: 33.33%;
    padding-left: map-get($grid-gutter-widths, lg) / 2;
    padding-right: map-get($grid-gutter-widths, lg) / 2;

    @media #{$media-xs} {
      width: 50%;
      padding-left: map-get($grid-gutter-widths, xs) / 2;
      padding-right: map-get($grid-gutter-widths, xs) / 2;
    }
  }

  .close-icon {
    padding: 18px 8px;
  }

  .search-input-group {
    z-index: 2;
    .clear-input {
     display: flex;
     align-items: center;
     justify-content: center;
    }
    input {
      color: #FFFFFF;
      background: none;
      width: calc( 100% - 74px);
      &::placeholder {
        color: #BEBEBE;
      }
      &:focus {
        background: none;
      }
      &:active {
        background: none;
      }
      height: 32px;
      line-height: 34px;
    }
    display: flex;
    border-bottom: 1px solid #FFFFFF;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #FFFFFF;
    transition: background-color 5000s ease-in-out 0s;
  }
  .search-icon {
    font-size: 21px;
  }

  .search-panel-input {
    // padding-bottom: 0;
    // padding-top: 0;
    border: none;
    // outline: 0;
    // font-size: 18px;
    // font-family: map-get($font-families, secondary);

    @media #{$media-xs} {
      font-size: 16px;
    }
  }

  .no-results {
    top: 80px;
    width: 100%;
  }

  .close-button {
    background: #fff;
  }

  button {
    @media #{$media-xs} {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}
.overlay {
  height: 100vh;
  top: 0;
  left: 0;
  // background-color: blue;
  opacity: 0.5;
  z-index: 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-in {
  &-left-enter-active,
  &-right-enter-active{
    transition: all 0.3s cubic-bezier(0.4, 0.0, 0.2, 1);
  }

  &-left-leave-active,
  &-right-leave-active {
    transition: all 0.3s cubic-bezier(0.4, 0.0, 1, 1);
  }
}
</style>
